<template>
  <router-link :to="workbookLink">
    <div class="worksheet__card">
      <div class="worksheet__img" @click="onUnlock()">
        <img :src="item.cover" />
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!item.free" v-bind:class="'worksheet__lock'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 9.28V6A6 6 0 003 6v3.28a4.49 4.49 0 00-3 4.22v6A4.51 4.51 0 004.5 24h9a4.51 4.51 0 004.5-4.5v-6a4.49 4.49 0 00-3-4.22zM9 18a2 2 0 110-4.001A2 2 0 019 18zm5-9H4V6a5 5 0 0110 0v3z" fill="#fff"/></svg>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.complete" v-bind:class="'worksheet__confirm'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M26.828 13.521l-11.74 10.957-3.912-3.913" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 37c9.941 0 18-8.059 18-18S28.941 1 19 1 1 9.059 1 19s8.059 18 18 18z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
      <div class="worksheet__footer">
        <p class="worksheet__title">{{ item.name }}</p>
        <p class="worksheet__p">{{ item.type }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { workbookType } from '@/components/workbook/workbook-data'
import { mapMutations } from 'vuex'
import { metaEventCustomActions } from '@/data/constants'
export default {
  name: 'WorkbookCard',
  props: {
    isThoughtRecordComplete: Boolean,
    hasThoughtChainEffectCompleted: Boolean,
    item: Object,
  },
  computed: {
    workbookLink() {
      if (
        (this.item.name === workbookType.THOUGHT_RECORD_REVIEW &&
          !this.isThoughtRecordComplete) ||
        (this.item.name === workbookType.THOUGHT_CHAIN_EFFECT &&
          !this.isThoughtRecordComplete) ||
        (this.item.name === workbookType.BAD_THOUGHT_DESTROYER &&
          !this.hasThoughtChainEffectCompleted)
      ) {
        return ''
      }
      if (this.item.complete) {
        this.RESET_WORKBOOK()
        return { name: 'ProfileWorkbook', params: { id: this.item.id } }
      } else {
        if (!this.item.free) return ''
        else {
          this.RESET_WORKBOOK()
          return { name: 'Workbook', params: { id: this.item.id } }
        }
      }
    },
  },
  methods: {
    ...mapMutations('workbook', ['RESET_WORKBOOK']),
    ...mapMutations('subscription', ['SET_OPEN']),
    onUnlock() {
      if (!this.item.complete && !this.item.free) {
        this.SET_OPEN({
          isOpen: true,
          action: metaEventCustomActions.ON_LOCKED_CLICKED,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.worksheet {
  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    margin-top: 12px;
  }

  &__p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    margin-top: 4px;
    text-transform: capitalize;
  }

  &__img {
    position: relative;
    border-radius: 12px;
    & > img {
      border-radius: 12px;
      width: 200px;
      height: 140px;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      background: rgba(#06191f, 0.4);
    }
  }

  &__lock {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
  }

  &__confirm {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
}
</style>
