<template>
  <div class="container container--big">
    <h3 class="worksheets__title">Therapy Worksheets</h3>
    <div class="worksheets__content">
      <template v-for="item in userWorkbooks" :key="item.id">
        <a-tooltip
          placement="top"
          v-if="
            (isThoughtRecordReview(item) && !isThoughtRecordComplete) ||
            (isThoughtChainEffect(item) && !isThoughtRecordComplete)
          "
        >
          <template #title>
            <span>Workbook "Thought Record" not completed yet</span>
          </template>
          <workbook-card
            :isThoughtRecordComplete="isThoughtRecordComplete"
            :item="item"
          />
        </a-tooltip>
        <a-tooltip
          placement="top"
          v-else-if="
            isBadThoughtsDestroyer(item) && !hasThoughtChainEffectCompleted
          "
        >
          <template #title>
            <span>Workbook "Thought Chain Effect" not completed yet</span>
          </template>
          <workbook-card
            :isThoughtRecordComplete="isThoughtRecordComplete"
            :hasThoughtChainEffectCompleted="hasThoughtChainEffectCompleted"
            :item="item"
          />
        </a-tooltip>
        <workbook-card
          v-else
          :isThoughtRecordComplete="isThoughtRecordComplete"
          :hasThoughtChainEffectCompleted="hasThoughtChainEffectCompleted"
          :item="item"
        />
      </template>
    </div>
    <subscription-modal @done="onDone" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SubscriptionModal from '../subscribe/SubscriptionModal.vue'
import { workbookType } from '@/components/workbook/workbook-data'
import WorkbookCard from '../workbook/WorkbookCard.vue'
export default {
  components: { SubscriptionModal, WorkbookCard },
  name: 'Worksheets',
  computed: {
    ...mapState('workbook', ['userWorkbooks']),
    isThoughtRecordComplete() {
      const thoughtRecord = this.userWorkbooks.find(
        (i) => i.name === workbookType.THOUGHT_RECORD
      )
      return thoughtRecord && thoughtRecord.complete
    },
    hasThoughtChainEffectCompleted() {
      const w = this.userWorkbooks.find(
        (i) => i.name === workbookType.THOUGHT_CHAIN_EFFECT
      )
      return w && w.complete
    },
  },
  created() {
    this.fetchUserWorkbooks()
  },
  methods: {
    ...mapActions('workbook', ['fetchUserWorkbooks']),
    ...mapMutations('workbook', ['RESET_WORKBOOK']),
    isThoughtRecordReview(item) {
      return workbookType.THOUGHT_RECORD_REVIEW === item.name
    },
    isBadThoughtsDestroyer(item) {
      return workbookType.BAD_THOUGHT_DESTROYER === item.name
    },
    isThoughtChainEffect(item) {
      return workbookType.THOUGHT_CHAIN_EFFECT === item.name
    },
    onDone() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.worksheets {
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-white);
    margin-bottom: 24px;
  }

  &__content {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, 200px);
  }
}

.worksheet {
  &__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    margin-top: 12px;
  }

  &__p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    margin-top: 4px;
    text-transform: capitalize;
  }

  &__img {
    position: relative;
    border-radius: 12px;
    & > img {
      border-radius: 12px;
      width: 200px;
      height: 140px;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      background: rgba(#06191f, 0.4);
    }
  }

  &__lock {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
  }

  &__confirm {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
}
</style>
